<template>
    <div id="cChart_Tornado" style="width:100%; height:100%;"></div>
</template>

<script setup type="ts">
    import { getOrCreateDataObject } from 'o365-dataobject';
    import { } from 'highcharts/code/modules/offline-exporting.js';

    const props = defineProps({
        load: function(options) {
            var dsUncertainties = this.dsUncertainties,
                dsOrgUnits = this.dsOrgUnits,
                renderChart = this.renderChart;

            if (options.OrgUnit_ID) {
                dsOrgUnits.recordSource.whereClause = "ID = " + options.OrgUnit_ID;
                dsOrgUnits.load();
            } else if (options.Risk_IDs) {
                dsUncertainties.recordSource.whereClause = `ID IN (${options.Risk_IDs})`;
                dsUncertainties.load();
            }

            dsOrgUnits.on("DataLoaded", function() {
                dsUncertainties.recordSource.whereClause = `OrgUnitIdPath LIKE '${dsOrgUnits.current.IdPath}%'`;
                dsUncertainties.load();
            });

            dsUncertainties.on("DataLoaded", function() {
                if (dsUncertainties.data.length == 0) {
                    return;
                };
                if (!options.OrgUnit_ID && !options.Risk_IDs) {
                    return;
                };
                renderChart(dsUncertainties.data);
            });
        },
        renderChart: function(riskData) {
            let vRisks = [],
                vCoefficientsThreats = [],
                vCoefficientsOpportunities  = [],
                vCurrency = '';

            riskData.forEach(function(pRow) {
                //add currency to title
                if (!vCurrency) {
                    vCurrency = ` (${pRow['Currency']})`;
                }

                vRisks.push(pRow['Name']);
                if (pRow['Type'] == 'Opportunity') { //oppportunity (TPE <= 0)
                    vCoefficientsThreats.push(''); 
                    vCoefficientsOpportunities.push(-(Number(pRow['CostTPEExpected'].toFixed(2)))); 
                } else { //threat (TPE >= 0)
                    vCoefficientsThreats.push((Number(pRow['CostTPEExpected'].toFixed(2)))); 
                    vCoefficientsOpportunities.push(''); 
                }
            });

            Highcharts.chart('cChart_Tornado', {
                chart: {
                    type: 'bar',
                    // plotBorderWidth: 1,
                },
                exporting: {
                    enabled: true,
                    fallbackToExportServer: false,
                    buttons: {
                        contextButton: {
                            menuItems: [
                                "viewFullscreen",
                                "printChart",
                                "downloadPNG",
                                "downloadJPEG",
                                "downloadPDF"
                            ]
                        }
                    },
                },
                lang: {
                    thousandsSep: ','
                },
                title: {
                    text: `Risk Drivers - Contribution to Variance${vCurrency}`,
                },
                subtitle: {
                    text: 'Regression Coefficients',
                    margin: 20,
                },
                xAxis: [{
                    categories: vRisks,
                    title: {
                        text: null
                    },
                    gridLineWidth: 1,
                    tickWidth: 1,
                    tickLength: 8,
                    tickmarkPlacement: 'on',
                }],
                yAxis: {
                    title: {
                        text: null,
                    },
                    labels: {
                        overflow: 'justify',
                        rotation: -90,
                    },
                    gridLineWidth: 1,
                    tickWidth: 1,
                    tickLength: 8,
                },
                plotOptions: {
                    series: {
                        stacking: 'normal',
                        dataLabels: {
                            enabled: true, //Display numbers at end of bar
                            pointFormat: '{point.y:,.0f}',
                        },
                    }
                },
                credits: {
                    enabled: false
                },
                series: [{
                    name: 'Coefficient Value (Threats)',
                    pointWidth: 20,
                    data: vCoefficientsThreats,
                    color: {
                        linearGradient: {
                            x1: 0,
                            x2: 0,
                            y1: 0,
                            y2: 1
                        },
                        stops: [
                            [0, '#750920'],
                            [1, '#b80f31']
                        ]
                    },
                    borderWidth: 1,
                    borderColor: 'black'
                },
                {
                    name: 'Coefficient Value (Opportunities)',
                    pointWidth: 20,
                    data: vCoefficientsOpportunities,
                    color: {
                        linearGradient: {
                            x1: 0,
                            x2: 0,
                            y1: 1,
                            y2: 0
                        },
                        stops: [
                            [0, '#092075'],
                            [1, '#0f31b8']
                        ]
                    },
                    borderWidth: 1,
                    borderColor: 'black'
                }]
            });
        },
            dsUncertainties: getOrCreateDataObject({
            "id": "dsUncertainties_" + crypto.randomUUID(),
            "viewName": "aviw_MSFT_Tornado",
            "distinctRows": false,
            "maxRecords": 10,
            "selectFirstRowOnLoad": true,
            "fields": [
                {"name": "Name"},
                {"name": "CostTPEExpected", "sortOrder": 1,"sortDirection": "desc"},
                // {"name": "NameAndTitle"},
                {"name": "Type"},
                {"name": "Currency"},
            ]
        }),
        dsOrgUnits: getOrCreateDataObject({
            "id": "dsOrgUnits_" + crypto.randomUUID(),
            "viewName": "stbv_System_OrgUnits",
            "distinctRows": false,
            "maxRecords": 1,
            "selectFirstRowOnLoad": true,
            "fields": [
                {"name": "OrgUnit"},
                {"name": "IdPath"}
            ]
        })
    });
</script>